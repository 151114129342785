import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { width as twWidth } from '../../tailwind'
import { hidden } from '../styles/utils'

const Wrapper = styled.svg`
	${tw`absolute`};
	stroke: currentColor;
	${props => props.hiddenMobile && hidden};
	color: ${props => props.stroke};
	width: ${props => props.svgWidth};
	fill: ${props => props.fill};
	left: ${props => props.left};
	top: ${props => props.top};
`

const icons = {
	triangle: {
		shape: (
			<polygon
				strokeWidth="1px"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				points="14.921,2.27 28.667,25.5 1.175,25.5 "
			/>
		),
		viewBox: '0 0 30 30',
	},
	circle: {
		shape: (
			<path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
		),
		viewBox: '0 0 30 30',
	},
	arrowUp: {
		shape: (
			<>
				<path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />{' '}
				<path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
			</>
		),
		viewBox: '0 0 30 42',
	},
	upDown: {
		shape: (
			<>
				<path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
				<path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
			</>
		),
		viewBox: '0 0 30 44.58',
	},
	box: {
		shape: (
			<path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
		),
		viewBox: '0 0 30 30',
	},
	hexa: {
		shape: (
			<polygon
				strokeLinejoin="round"
				strokeMiterlimit="10"
				points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
			/>
		),
		viewBox: '0 0 30 30',
	},
	star: {
		shape: (
			<polygon
				strokeWidth="1px"
				strokeLinejoin="round"
				points="100,10 40,198 190,78 10,78 160,198"
			/>
		),
		viewbox: '100 100 100 100',
	},
	cross: {
		shape: (
			<path
				strokeWidth="3px"
				d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
			/>
		),
		viewBox: '0 0 100 100',
	},
}

const SVG = ({ stroke, fill, width, icon, left, top, hiddenMobile }) => (
	<Wrapper
		viewBox={icons[icon].viewBox}
		stroke={stroke}
		fill={fill}
		svgWidth={twWidth[`${width}`]}
		left={left}
		top={top}
		hiddenMobile={hiddenMobile}
	>
		{icons[icon].shape}
	</Wrapper>
)

export default SVG

SVG.propTypes = {
	stroke: PropTypes.string,
	fill: PropTypes.string,
	width: PropTypes.number,
	icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
	left: PropTypes.string,
	top: PropTypes.string,
	hiddenMobile: PropTypes.bool,
}

SVG.defaultProps = {
	stroke: 'transparent',
	width: 8,
	fill: 'none',
	left: '0%',
	top: '0%',
	hiddenMobile: false,
}
