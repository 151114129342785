import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Grid from '@material-ui/core/Grid';
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'
import FormDialog from '../components/FormDialog'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

const ProjectsWrapper = styled.div`
	${tw`flex flex-wrap justify-between mt-8`};
	display: grid;
	grid-gap: 4rem;
	grid-template-columns: repeat(2, 1fr);
	@media (max-width: 1200px) {
		grid-gap: 3rem;
	}
	@media (max-width: 900px) {
		grid-template-columns: 1fr;
		grid-gap: 2rem;
	}
`

const AboutHero = styled.div`
	${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const AboutSub = styled.span`
	${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
	${tw`text-white text-xl md:text-2xl font-serif text-left`};
`

const ContactText = styled.p`
	${tw`text-grey-light font-sans sm:text-lg text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
	${tw`text-center text-grey absolute pin-b p-6 font-sans text-sm lg:text-lg`};
`
const ServicesTitle = styled.h3`
	${tw`-my-2 lg:my-0 text-2xl lg:text-3xl`}
`

const ServicesText = styled.p`
	${tw`lg-:m-0 text-xl lg:text-2xl`}
`

const Index = () => (
	<>
		<Layout />
		<Parallax pages={5}>
			<Hero offset={0}>
				<BigTitle>syte.dev</BigTitle>
				<Subtitle>
					Web design for the next generation of young professionals and consumer-facing companies. Scroll
					down to see what we can do for you.
					<br /> <br />
					<div style={{display: 'inline-flex'}}>
						<div style={{margin: 'auto'}}>
							Contact us &nbsp;
						</div>
						<div style={{alignSelf: 'center'}}>
							<FormDialog />
						</div>
					</div>
				</Subtitle>
			</Hero>
			<Projects offset={1}>
				<Title>Our Work</Title>
				<ProjectsWrapper>
					<ProjectCard
						title="Personal"
						link="#"
						bg="linear-gradient(to right, #ff0844 0%, #ffb199 100%)"
					>
						A personal website showcasing your achievements.
					</ProjectCard>
					<ProjectCard
						title="Portfolio"
						link="#"
						bg="linear-gradient(to left, #cc208e 0%, #6713d2 100%)"
					>
						A beautiful portfolio showcasing your art and photographs.
					</ProjectCard>
					<ProjectCard
						title="Developer"
						link="#"
						bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
					>
						A website for developers to show off their GitHub projects.
					</ProjectCard>
					<ProjectCard
						title="Start Up"
						link="#"
						bg="linear-gradient(to right, #43e97b 0%, #38f9d7 100%)"
					>
						An eye catching front page for emerging start ups.
					</ProjectCard>
				</ProjectsWrapper>
			</Projects>
			<About offset={3}>
				<Title>Services</Title>
				<AboutDesc>
					<Grid container alignItems="center" alignContent="center" justify="space-evenly">
						<Grid item>
							<ServicesTitle>Responsive Websites</ServicesTitle>
							<ServicesText>
								We build beautiful, presentable websites that capture your audience on any platform. 
								With a focus on visitor retention, we design our sites to be visually appealing, quick to 
								load, and simple to use.
							</ServicesText>
						</Grid>
						<Grid item>
							<ServicesTitle>Search Engine Optimisation (SEO)</ServicesTitle>
							<ServicesText>
								All of our sites are tracked by search engines and come with the means to extensively customise
								how the website is crawled and tracked. The content is entirely indexable and easily found.
							</ServicesText>
						</Grid>
						<Grid item>
							<ServicesTitle>Hosting, Deployment</ServicesTitle>
							<ServicesText>
								Get your web presence up on the internet for everyone to see. We can get your site up in minutes and
								have your own custom domain point to it. We also offer consultation regarding all of the above.
							</ServicesText>
						</Grid>
					</Grid>
				</AboutDesc>
			</About>
			<Contact offset={4}>
				<Inner>
					<Title>
						<div style={{ display: 'inline-flex' }}>
							{' '}
							Contact us &nbsp;
							<div style={{ alignSelf: 'center' }}>
								<FormDialog />
							</div>
						</div>
					</Title>
					<ContactText>
						Click the icon to contact us.
					</ContactText>
				</Inner>
				<Footer>
					&copy; 2021 {' '}
					<a href="https://syte.dev">syte.dev</a> Made by{' '}
					<a href="mailto:adthy@pm.me">Adithya</a>.
				</Footer>
			</Contact>
		</Parallax>
	</>
);

export default Index
