const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"
  siteTitle: 'SyteDev', // Navigation and Site Title
  siteTitleAlt: 'syte', // Alternative Site title for SEO
  siteTitleShort: 'syte', // short_name for manifest
  siteHeadline: 'Building attractive sites at a quick pace.', // Headline for schema.org JSONLD
  siteUrl: 'https://syte.dev', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteDescription: 'Website development and design for the next generation of young professionals and consumer-facing companies. \
  We develop for students, workers, artists, and start ups. If you need a landing page, a resume site, or a portfolio, check us out.',
  author: 'Syte', // Author for schema.org JSONLD
  ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-146055716-1',
  // Manifest and Progress color
  themeColor: tailwind.colors.indigo,
  backgroundColor: tailwind.colors.blue,
}
