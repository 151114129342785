import React from 'react'
import Fab from '@material-ui/core/Fab'
import SendIcon from '@material-ui/icons/Send'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { FormComponent } from './FormComponent'
import AlertDialog from './Alert'

export default function FormDialog() {
	const [open, setOpen] = React.useState(false)
	const [alertOpen, alertSetOpen] = React.useState(false)

	function handleClickOpen() {
		setOpen(true)
	}

	function handleClose() {
		setOpen(false)
	}

	function handleAlertOpen() {
		alertSetOpen(true)
	}

	function handleAlertClose(){
		alertSetOpen(false)
	}

	const FormSchema = Yup.object().shape({
		email: Yup.string()
			.email('Invalid email address')
			.required('Required'),
		name: Yup.string()
			.min(2, 'Must be longer than 2 characters')
			.max(20, 'Nice try, nobody has a name that long')
			.required('Required'),
		desc: Yup.string()
			.min(2, 'Must be longer than that.')
			.required('Required'),
	})

	const values = { name: '', email: '', desc: '' }

	function submitValues({ name, email, desc }) {
		handleClose()
		const formData = new FormData()
		formData.append("entry.1539359299", name)
		formData.append("entry.1150023909", email)
		formData.append("entry.160137867", desc)
		axios
			.post('https://docs.google.com/forms/d/e/1FAIpQLSdKFSRBMMK_4uMU3LuM4u2d9mK-0XBFnviDvV7HAp9SsEZqAw/formResponse', 
				formData)
			.then(function(response) {
				console.log(response);
			})
			.catch(function(error) {
				console.log(error)
			})
		handleAlertOpen()
	}

	return (
		<div>
			<Fab size="small" aria-label="Send" onClick={handleClickOpen}>
				<SendIcon />
			</Fab>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Let us know what you need.</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Give us a description of what you're looking to purchase and your contact details. We'll get back to you for
						a free consultation on how we can help.
					</DialogContentText>
					<Formik
						render={props => <FormComponent {...props} />}
						initialValues={values}
						validationSchema={FormSchema}
						//action="https://docs.google.com/forms/d/e/1FAIpQLSdKFSRBMMK_4uMU3LuM4u2d9mK-0XBFnviDvV7HAp9SsEZqAw/formResponse"
						onSubmit={submitValues}
					/>
				</DialogContent>
			</Dialog>
			<AlertDialog 
				alertOpen={alertOpen} 
				handleAlertClose={handleAlertClose} 
				alertSetOpen={alertSetOpen} 
			/>
		</div>
	)
}
