import React from 'react'
import GlobalStyle from '../styles/global'
import SEO from './SEO'
import 'typeface-cantata-one'
import 'typeface-open-sans'
import 'typeface-maven-pro'
import 'typeface-roboto'

const Layout = () => (
	<>
		<SEO />
		<GlobalStyle />
	</>
)

export default Layout