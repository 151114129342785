import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'

export const FormComponent = (props) => {

	const {
		values: { name, email, desc },
		errors,
		touched,
		handleSubmit,
		handleChange,
		isValid,
		setFieldTouched,
		setOpen
	} = props
	
	const change = (name, e) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false)
	}

	return (
		<form onSubmit={handleSubmit}>
			<TextField
				autoComplete="name"
				margin="normal"
				id="name"
				label="Name"
				// name="entry.1539359299"
				helperText={touched.name ? errors.name : ''}
				error={touched.name && Boolean(errors.name)}
				value={name}
				variant="outlined"
				onChange={change.bind(null, 'name')}
				fullWidth
			/>
			<TextField
				autoComplete="email"
				margin="normal"
				id="email"
				label="Email Address"
				// name="entry.1150023909"
				type="email"
				value={email}
				helperText={touched.email ? errors.email : ''}
				error={touched.email && Boolean(errors.email)}
				variant="outlined"
				onChange={change.bind(null, 'email')}
				required
				fullWidth
			/>
			<TextField
				margin="normal"
				id="desc"
				label="Description"
				// name="entry.160137867"
				multiline
				rows="4"
				placeholder="What kind of project or service do you need? You can also mention additional contact info here."
				fullWidth
				helperText={touched.desc ? errors.desc : ''}
				error={touched.desc && Boolean(errors.desc)}
				value={desc}
				variant="outlined"
				onChange={change.bind(null, 'desc')}
				required
			/>
			<Button type="submit" color="primary" disabled={!isValid}>
				Send
			</Button>
		</form>
	)
}
